import "core-js/stable";
import "regenerator-runtime/runtime";
import "jquery-modal";
import "vendor/sortable";

import "src/rails";
import "src/flash";
import "src/action_text";
import "src/browser_timezone";
import "src/blog_attachment_new_tab";
import "src/carousel";
import "src/accordion";
import "src/tabs";
import "src/homepage";
import "src/map";
import "src/typeahead";
import "src/registry_search";
import "src/forms";
import "src/on_user_data_change";
import "src/credit_card";
import "src/offspring";
import "src/square_header";
import "src/mobile"
import "src/flowbite"
import "src/header"
import "src/memberSidebar"
import "src/stepper"
import "src/membershipChose"
import "src/summaryDropdown"
import "src/peopleSlider"
import "src/alhemyRestyleLogic"


document.addEventListener("turbolinks:load", function() {
  document.querySelectorAll('*').forEach(el => {
    const styles = getComputedStyle(el);
    if (styles.color === 'rgb(0, 0, 0)') {
      el.style.color = '#111928';
    }
  });
});

const fixedOnScroll = (element) => {
  const elementTopOffset = element.offsetTop;

  if (window.scrollY > elementTopOffset) {
    element.classList.add("fixed-on-scroll");
  }

  document.addEventListener(
    "scroll",
    (e) => {
      const windowScroll = window.scrollY;

      if (windowScroll > elementTopOffset) {
        element.classList.add("fixed-on-scroll");
      } else {
        element.classList.remove("fixed-on-scroll");
      }
    },
    { capture: false, passive: true }
  );
};

const collapsible = (element) => {
  const header = element.querySelector("header");
  const headerIcon = header.querySelector("icon");

  const body = element.querySelector(".collapsible-body");

  const updateIcon = (hidden) => {
    if (hidden)
      headerIcon.classList.replace("fa-chevron-up", "fa-chevron-down");
    else headerIcon.classList.replace("fa-chevron-down", "fa-chevron-up");
  };

  updateIcon(body.classList.contains("hidden"));

  header.addEventListener("click", () => {
    updateIcon(body.classList.toggle("hidden"));
  });
};

const truthifyHidden = (element) => {
  const hiddenInput = element.parentNode.querySelector(`input[type="hidden"]`);

  element.addEventListener("click", () => {
    hiddenInput.value = true;
    element.innerHTML = `
      <icon class="fas fa-circle-notch fa-spin"></icon>
      Loading ...
    `;
  });
};

const newImageUpload = (element) => {
  // This clicks the button instead of using element.form.submit() as direct
  // uploads from activestorage don't hook into listening for submit() ಠ_ಠ
  const submitOnChange = () =>
    element.form
      .querySelector(`input[type="submit"], button[type="submit"]`)
      .click();

  element.addEventListener("change", submitOnChange);
  element.addEventListener("ondrop", submitOnChange);
};

document.addEventListener("turbolinks:load", () => {
  document
    .querySelectorAll(`[data-behavior~=fixed-on-scroll]`)
    .forEach(fixedOnScroll);
  document
    .querySelectorAll(`[data-behavior~=collapsible]`)
    .forEach(collapsible);
  document
    .querySelectorAll(`[data-behavior~=truthify-hidden]`)
    .forEach(truthifyHidden);
  document
    .querySelectorAll(`[data-behavior~=new-image-upload]`)
    .forEach(newImageUpload);

  document.addEventListener("direct-upload:initialize", (event) => {
    const { target, detail } = event;
    const { id, file } = detail;
    target.insertAdjacentHTML(
      "beforebegin",
      `
      <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename">${file.name}</span>
      </div>
    `
    );
  });

  document.addEventListener("direct-upload:start", (event) => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.remove("direct-upload--pending");
  });

  document.addEventListener("direct-upload:progress", (event) => {
    const { id, progress } = event.detail;
    const progressElement = document.getElementById(
      `direct-upload-progress-${id}`
    );
    progressElement.style.width = `${progress}%`;
  });

  document.addEventListener("direct-upload:error", (event) => {
    event.preventDefault();
    const { id, error } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add("direct-upload--error");
    element.setAttribute("title", error);
  });

  document.addEventListener("direct-upload:end", (event) => {
    const { id } = event.detail;
    const element = document.getElementById(`direct-upload-${id}`);
    element.classList.add("direct-upload--complete");
  });
});

const tableRowNavigate = (event, url) => {
  // What. A. Hack.
  if (event.target.href) return;

  Turbolinks.visit(url);
};

window.tableRowNavigate = tableRowNavigate;
