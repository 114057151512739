document.addEventListener('turbolinks:load', function() {
  const planOptions = document.querySelectorAll('input[name="plan"]');
  const planButton = document.querySelector('#plan-buttons a');
  const permanentPlan = document.getElementById('permanentPlan');
  const yearlyPlan = document.getElementById('yearlyPlan');
  const permanentPlanRadio = document.getElementById('permanentPlanRadio');
  const yearlyPlanRadio = document.getElementById('yearlyPlanRadio');

  planOptions.forEach((option) => {
    option.addEventListener('click', function() {
      let membershipPlanId;

      if (this.value === 'yearly') {
        membershipPlanId = 2;
        yearlyPlanRadio.classList.add("priceRadioActive");
        permanentPlanRadio.classList.remove("priceRadioActive");
        permanentPlan.classList.remove("plan-option-active");
        yearlyPlan.classList.add("plan-option-active");
      } else if (this.value === 'permanent') {
        membershipPlanId = 3;
        yearlyPlanRadio.classList.remove("priceRadioActive");
        permanentPlanRadio.classList.add("priceRadioActive");
        permanentPlan.classList.add("plan-option-active");
        yearlyPlan.classList.remove("plan-option-active");
      }

      if (planButton) {
        planButton.setAttribute('href', `/my/memberships/new?membership_plan_id=${membershipPlanId}`);
      }
    });
  });
});
