document.addEventListener("turbolinks:load", async () => {

  // Handler for existing offspring
  $(".remove-offspring").click((obj) => {
    obj.stopPropagation();

    $(obj.currentTarget)
      .parents(".offspring_row")
      .addClass('hide');

    $(obj.currentTarget).parents('.offspring_row').find('.destroy').val('true');

    return false;
  });

  function offspring_index() {
    var reg = new RegExp('[0-9]+');
    var text = $('.offspring-index').parent('.offspring_row').not('.hide').last().text();
    if (text.length > 0) {
    var number = reg.exec(text)[0];
      return number * 1 + 1;
    } else {
      return 1;
    }
  }

  function yearDropdownOptions()  {
    var maxYear = (new Date).getFullYear() + 5;
    var dropdownOptions = "";
    for (var i = 1924 ; i <= maxYear ;  i++) {
      dropdownOptions += `<option value=${i}>${i}</option>`;
    }
    return dropdownOptions;
  }

  $("#add-offspring").click((e) => {
    e.stopPropagation();
    var i = Date.now();
    $("#offspring_rows").append(
      "<div class='mb-5 offspring_row remove-offspring-row'>" +
        "<p class='offspring-index text-gray-700'>Offspring # " + offspring_index() + "</p>" +
        "<div class='signUpField'>" +
          "<label class='karla-500 signUpLabel block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Birthdate:</label>" +
          "<div class='flex space-x-2'>" +
            "<div>" +
              "<div class='field_with_errors'>" +
                "<div class='form-select'>" +
                  "<select class='dateSelector bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' name='post[offspring_attributes][" + i + "][birthdate_month]' style='color: rgb(150, 164, 184);'>" +
                    "<option value=''>Month</option>" +
                    "<option value='1'>January</option>" +
                    "<option value='2'>February</option>" +
                    "<option value='3'>March</option>" +
                    "<option value='4'>April</option>" +
                    "<option value='5'>May</option>" +
                    "<option value='6'>June</option>" +
                    "<option value='7'>July</option>" +
                    "<option value='8'>August</option>" +
                    "<option value='9'>September</option>" +
                    "<option value='10'>October</option>" +
                    "<option value='11'>November</option>" +
                    "<option value='12'>December</option>" +
                  "</select>" +
                "</div>" +
              "</div>" +
            "</div>" +
            "<div>" +
              "<div class='field_with_errors'>" +
                "<div class='form-select'>" +
                  "<select class='dateSelector bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' name='post[offspring_attributes][" + i + "][birthdate_day]' style='color: rgb(150, 164, 184);'>" +
                    "<option value=''>Day</option>" +
                    Array.from({ length: 31 }, (_, index) => "<option value='" + (index + 1) + "'>" + (index + 1) + "</option>").join('') +
                  "</select>" +
                "</div>" +
              "</div>" +
            "</div>" +
            "<div>" +
              "<div class='field_with_errors'>" +
                "<div class='form-select'>" +
                  "<select class='dateSelector bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' name='post[offspring_attributes][" + i + "][birthdate_year]' style='color: rgb(150, 164, 184);'>" +
                    "<option value=''>Year</option>" +
                    yearDropdownOptions() +
                  "</select>" +
                "</div>" +
              "</div>" +
            "</div>" +
          "</div>" +
        "</div>" +
        "<div class='signUpField'>" +
          "<label class='karla-500 signUpLabel block mb-2 text-sm font-medium text-gray-900 dark:text-white'>Biological Sex:</label>" +
          "<div class='form-select'>" +
            "<select class='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' name='post[offspring_attributes][" + i + "][gender]' style='color: rgb(150, 164, 184);'>" +
              "<option value=''></option>" +
              "<option value='male'>Male</option>" +
              "<option value='female'>Female</option>" +
            "</select>" +
          "</div>" +
        "</div>" +
        "<a class='upgradePlanButton mt-5 karla-500 bnt-imaged text-white btn-orange hover rounded-lg text-sm text-center me-2 mb-2 focus:outline-none headerBtn remove-new-offspring'>" +
          "<i class='fas fa-minus'></i>" +
          "Remove Offspring" +
        "</a>" +
      "</div>"
    );






    $(".remove-new-offspring").click((obj) => {
      obj.stopPropagation();
      $(obj.currentTarget)
        .parent(".offspring_row")
        .remove();
      return false;
    });

    return false;
  });
});
