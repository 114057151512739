
document.addEventListener('turbolinks:load', function () {
  const sidebar = document.getElementById('default-sidebar');
  const toggleButton = document.getElementById('toggleSidebarButton');
  const body = document.body;

  toggleButton.addEventListener('click', function () {
    const isCollapsed = sidebar.classList.toggle('collapsed');
    if (isCollapsed) {
      body.classList.remove('sidebarOpened');
    } else {
      body.classList.add('sidebarOpened');
    }
  });
});

  document.addEventListener('turbolinks:load', function() {
    const sidebar = document.getElementById('sidebar');
    const overlay = document.getElementById('overlay');
    const memberToolsButton = document.getElementById('member-tools-button');
    const closeButton = document.getElementById('block-box-button');

    memberToolsButton.addEventListener('click', function() {
      sidebar.classList.remove('hidden');
      overlay.classList.remove('hidden');

      setTimeout(function() {
        sidebar.classList.add('show');
        overlay.classList.add('show');
      }, 10);

      document.body.classList.add('overflow-hidden');
    });

    function closeSidebar() {
      sidebar.classList.remove('show');
      overlay.classList.remove('show');

      sidebar.addEventListener('transitionend', function handler() {
        sidebar.classList.add('hidden');
        overlay.classList.add('hidden');
        sidebar.removeEventListener('transitionend', handler);
      });

      document.body.classList.remove('overflow-hidden');
    }

    closeButton.addEventListener('click', closeSidebar);
    overlay.addEventListener('click', closeSidebar);
  });


  document.addEventListener('turbolinks:load', function() {
    const sidebar = document.getElementById('sidebarRight');
    const overlay = document.getElementById('overlayLeft');
    const openSidebarButton = document.getElementById('rightSidebarButton');
    const closeSidebarButton = document.getElementById('block-box-button-left');

    openSidebarButton.addEventListener('click', function() {
      sidebar.classList.remove('hidden');
      overlay.classList.remove('hidden');

      setTimeout(function() {
        sidebar.classList.add('show');
        overlay.classList.add('show');
      }, 10);

      document.body.classList.add('overflow-hidden');
    });

    function closeSidebar() {
      sidebar.classList.remove('show');
      overlay.classList.remove('show');

      sidebar.addEventListener('transitionend', function handler() {
        sidebar.classList.add('hidden');
        overlay.classList.add('hidden');
        sidebar.removeEventListener('transitionend', handler);
      });

      document.body.classList.remove('overflow-hidden');
    }

    closeSidebarButton.addEventListener('click', closeSidebar);
    overlay.addEventListener('click', closeSidebar);
  });


  document.addEventListener('turbolinks:load', () => {
    document.querySelectorAll('[data-toggle="collapse"]').forEach(toggle => {
      toggle.addEventListener('click', (e) => {
        e.preventDefault();
        const subMenu = toggle.nextElementSibling;
        const icon = toggle.querySelector('img');

        if (subMenu.style.maxHeight) {
          subMenu.style.maxHeight = null;
          icon.classList.remove('rotate-0');
          icon.classList.add('rotate-180');
        } else {
          subMenu.style.maxHeight = subMenu.scrollHeight + "px";
          icon.classList.remove('rotate-180');
          icon.classList.add('rotate-0');
        }
      });
    });
  });

  document.addEventListener('turbolinks:load', function () {
    const toggles = document.querySelectorAll('[data-toggle="collapse"]');
    toggles.forEach(toggle => {
      toggle.addEventListener('click', function () {
        const content = this.nextElementSibling;
        const arrow = this.querySelector('.arrow-icon img');
        const parentTitle = this.closest('.rightMenuTitle');

        if (content.classList.contains('open')) {
          content.classList.remove('open');
          arrow.classList.remove('rotate-180');
          arrow.classList.add('rotate-0');
          parentTitle.classList.remove('active');
        } else {
          content.classList.add('open');
          arrow.classList.remove('rotate-0');
          arrow.classList.add('rotate-180');
          parentTitle.classList.add('active');
        }
      });
    });
  });
