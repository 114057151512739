document.addEventListener('turbolinks:load', (event) => {
  const toggleDropdown = (icon) => {
    if (icon) {
      icon.classList.toggle('rotate-icon');
    }
  };

  const handleDropdownToggle = (menuId, iconSelector) => {
    const menu = document.getElementById(menuId);
    if (menu) {
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (mutation.type === 'attributes' && (mutation.attributeName === 'class')) {
            const icon = document.querySelector(iconSelector);
            if (menu.classList.contains('block') && !menu.classList.contains('hidden')) {
              icon.classList.add('rotate-icon');
            } else {
              icon.classList.remove('rotate-icon');
            }
          }
        });
      });

      observer.observe(menu, { attributes: true });
    }
  };

  handleDropdownToggle('membershipDropdownMenu', '#membershipDropdown .membershipLinkIcon');
  handleDropdownToggle('aboutDropdownMenu', '#aboutDropdown .AboutLinkIcon');
  handleDropdownToggle('supportDropdownMenu', '#supportDropdown .supportLinkIcon');
  handleDropdownToggle('resourcesDropdownMenu', '#resourcesDropdown .resourcesLinkIcon');
});

document.addEventListener('DOMContentLoaded', () => {
  const dropdownToggle = document.querySelector('[data-dropdown-toggle="profileDropDown"]');
  const dropdownMenu = document.getElementById('profileDropDown');
  const svgIcon = dropdownToggle.querySelector('svg');

  dropdownToggle.addEventListener('click', () => {
    dropdownMenu.classList.toggle('hidden');
    if (dropdownMenu.classList.contains('hidden')) {
      svgIcon.style.transform = 'rotate(0deg)';
    } else {
      svgIcon.style.transform = 'rotate(180deg)';
    }
    svgIcon.style.transition = 'transform 0.1s ease';
  });
});
