

  document.addEventListener("turbolinks:load", function () {
    const steps = document.querySelectorAll(".registration-step");
    const nextButtons = document.querySelectorAll(".button-next");
    const backButtons = document.querySelectorAll(".button-back");
    const submitButton = document.querySelector(".button-submit");
    const buttons = document.querySelector('#stepperButtons')
    let currentStep = 1;

    nextButtons.forEach(button => {
      button.addEventListener("click", function () {
        const nextStep = `step-${++currentStep}`;
        showStep(nextStep);
        if (currentStep==3){
         buttons.className='fieldsetContainer questionStep buttonsLine'
        } else{
          buttons.className='actions fieldsetContainer actionsContainer'
        }
      });
    });

    backButtons.forEach(button => {
      button.addEventListener("click", function () {
        const previousStep = `step-${--currentStep}`;
        showStep(previousStep);
        if (currentStep==3){
          buttons.className='fieldsetContainer questionStep buttonsLine'
        } else{
          buttons.className='actions fieldsetContainer actionsContainer'
        }
      });
    });

    function showStep(stepId) {
      steps.forEach(step => {
        if (step.id === stepId) {
          step.classList.remove("hidden");
        } else {
          step.classList.add("hidden");
        }
      });

      if (currentStep === 1) {
        backButtons.forEach(button => button.classList.add("hidden"));
      } else {
        backButtons.forEach(button => button.classList.remove("hidden"));
      }

      if (currentStep === steps.length-1) {
        nextButtons.forEach(button => button.classList.add("hidden"));
        submitButton.classList.remove("hidden");
      } else {
        nextButtons.forEach(button => button.classList.remove("hidden"));
        submitButton.classList.add("hidden");
      }
    }
      showStep("step-1");
  });


  document.addEventListener("turbolinks:load", function() {
    const togglePassword = document.getElementById("toggle-password");
    const confirmationPassword = document.getElementById("logInPassword");
    const passwordField = document.getElementById("logInPassword");
    passwordField.setAttribute("type", "password");
    confirmationPassword.setAttribute("type", "password");

    togglePassword.addEventListener("click", function() {
      const type = passwordField.getAttribute("type") === "password" ? "text" : "password";
      passwordField.setAttribute("type", type);

      this.classList.toggle("fa-eye-slash");
      this.classList.toggle("fa-eye");
    });
  });

  document.addEventListener("turbolinks:load", function() {
    const togglePassword = document.getElementById("toggle-password");
    const confirmationPassword = document.getElementById("password_confirmation");
    const passwordField = document.getElementById("password");
    passwordField.setAttribute("type", "password");
    confirmationPassword.setAttribute("type", "password");

    togglePassword.addEventListener("click", function() {
      const type = passwordField.getAttribute("type") === "password" ? "text" : "password";
      passwordField.setAttribute("type", type);

      this.classList.toggle("fa-eye-slash");
      this.classList.toggle("fa-eye");
    });
  });

  document.addEventListener("turbolinks:load", function() {
    const togglePassword = document.getElementById("toggle-password-conf");
    const confirmationPassword = document.getElementById("password_confirmation");
    const passwordField = document.getElementById("password_confirmation");
    passwordField.setAttribute("type", "password");
    confirmationPassword.setAttribute("type", "password");

    togglePassword.addEventListener("click", function() {
      const type = passwordField.getAttribute("type") === "password" ? "text" : "password";
      passwordField.setAttribute("type", type);

      this.classList.toggle("fa-eye-slash");
      this.classList.toggle("fa-eye");
    });
  });
