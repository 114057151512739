/*Workaround to gray out the placeholder of every select and date field and once
 changed set the color to black
*/

document.addEventListener("turbolinks:load", function() {
  var selects = document.querySelectorAll('select');
  var dateFields = document.querySelectorAll("input[type='date']");

  selects.forEach(function(select) {
    if (select[0].value == "" && select.value == "") {
      select.style.setProperty('color', 'rgb(150,164,184)');
    }
    select.addEventListener('change', changeColor);
  });

  dateFields.forEach(function(dateField) {
    if (dateField.value == "") {
      dateField.style.setProperty('color', 'rgb(150,164,184)');
    }
    dateField.addEventListener('change', changeColor);
  });

  function changeColor(event) {
    event.target.style.setProperty('color', 'black', 'important');
  }

  // if user presses enter submit form, if he presses shift + enter
  // add a new line
  $("#search_by_keyword").keypress(function (e) {
    if(e.which == 13 && !e.shiftKey) {
      $(this).closest("form").submit();
      e.preventDefault();
    }
  });


  // Membership handler for admins

  $('.admin#user_membership_plan_id').on('change', function() {
    // If 'Yearly' was select then set the next expires at date
    if ($(this).val() == '2') {
      var today = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
      var new_expire_date = today.toISOString().substring(0, 10);
      $("#user_membership_expires_at").val(new_expire_date);
    } else {
      $("#user_membership_expires_at").val("");
    }
  });


  document.querySelectorAll('.postRigthInfoBlock').forEach(function(block) {
    block.addEventListener('mouseenter', function() {
      const rightBlockId = block.id;
      const postId = rightBlockId.replace('post_right_', '');
      const mainBlock = document.getElementById(`post_${postId}`);

      if (mainBlock) {
        mainBlock.style.backgroundColor = 'rgb(240, 240, 240)';
        mainBlock.style.borderRadius = '16px';

      }
    });

    block.addEventListener('mouseleave', function() {
      const rightBlockId = block.id;
      const postId = rightBlockId.replace('post_right_', '');
      const mainBlock = document.getElementById(`post_${postId}`);

      if (mainBlock) {
        mainBlock.style.backgroundColor = '';
      }
    });
  });

  const donorNotesElements = document.querySelectorAll('.post-donor-notes');

  donorNotesElements.forEach(element => {
    const words = element.textContent.split(/\s+/);

    const modifiedText = words.map(word => {
      if (word.match(/\b(https?:\/\/|www\.)\S+/) || word.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        return `<span style="word-break: break-all">${word}</span>`;
      }
      return word;
    }).join(' ');

    element.innerHTML = modifiedText;
  });


  const allDetails = document.querySelectorAll('details.detailsBorder');

  allDetails.forEach(details => {
    const summary = details.querySelector('summary.alphDropDown');
    const facilityItems = details.querySelectorAll('.facility-item');

    if (facilityItems.length === 0) {
      return;
    }

    details.addEventListener('toggle', () => {
      if (details.open) {
        console.log('open')
        facilityItems.forEach(item => {
          const lineHeight = parseFloat(getComputedStyle(item).lineHeight);
          const height = item.offsetHeight;
          if (height > lineHeight) {
            item.style.marginBottom = '10px';
          } else {
            item.style.marginBottom = '0';
          }
        });
      }
    });
  });



  const urlParams = new URLSearchParams(window.location.search);
  const activeTab = urlParams.get('tab');

  if (activeTab) {
    const tabPanes = document.querySelector('.tab-panes.h-full');
    if (tabPanes) {
      const articles = tabPanes.querySelectorAll('article');
      articles.forEach(article => article.classList.remove('active'));

      const targetArticle = tabPanes.querySelector(`#${activeTab}`);
      if (targetArticle) {
        targetArticle.classList.add('active');
      }
    }

    const postViewTabs = document.querySelector('nav.postViewTabs');
    if (postViewTabs) {
      const navLinks = postViewTabs.querySelectorAll('a');
      navLinks.forEach(link => link.classList.remove('active'));

      const targetLink = postViewTabs.querySelector(`#${activeTab}-tab`);
      if (targetLink) {
        targetLink.classList.add('active');
      }
    }
  }



    const carouselWrapper = document.querySelector('#carouselWrapper');
    const prevButton = document.querySelector('[data-carousel-prev]');
    const nextButton = document.querySelector('[data-carousel-next]');

    let isDragging = false;
    let startX = 0;
    let currentX = 0;
    let diff = 0;
    let canDrag = true;

    function handleDragSwitch() {
      if (diff > 50) {
        nextButton.click();
        startX = currentX;
        isDragging = false;
        canDrag = false;
        setTimeout(() => { canDrag = true; }, 600);
      } else if (diff < -50) {
        prevButton.click();
        startX = currentX;
        isDragging = false;
        canDrag = false;
        setTimeout(() => { canDrag = true; }, 1000);
      }
    }

    carouselWrapper.addEventListener('mousedown', function (e) {
      if (!canDrag) return;
      isDragging = true;
      startX = e.pageX;
      currentX = startX;
      diff = 0;
    });

    carouselWrapper.addEventListener('mousemove', function (e) {
      if (!isDragging || !canDrag) return;
      currentX = e.pageX;
      diff = startX - currentX;
      handleDragSwitch();
    });

    carouselWrapper.addEventListener('mouseup', function () {
      if (isDragging) {
        isDragging = false;
      }
    });

    carouselWrapper.addEventListener('touchstart', function (e) {
      if (!canDrag) return;
      isDragging = true;
      startX = e.touches[0].pageX;
      currentX = startX;
      diff = 0;
    });

    carouselWrapper.addEventListener('touchmove', function (e) {
      if (!isDragging || !canDrag) return;
      currentX = e.touches[0].pageX;
      diff = startX - currentX;
      handleDragSwitch();
    });

    carouselWrapper.addEventListener('touchend', function () {
      if (isDragging) {
        isDragging = false;
      }
    });
});
