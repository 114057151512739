document.addEventListener("turbolinks:load", () => {
  $('.notifications').fadeOut(10000);
  $('.notifications').hover(function() {
    $(this).stop();
    $(this).fadeIn();
  });


  const setCloseHandler = (element) => {
    element.addEventListener("click", (event) => {
      element.closest(".notification").remove();
    });
  };

  document.querySelectorAll(".notification .delete").forEach(setCloseHandler);
  document
    .querySelectorAll('[data-behavior="close-notification"]')
    .forEach(setCloseHandler);
});
